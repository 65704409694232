import {Configurations} from '@domain/model/Config/Configurations';
import {Configuration} from '@domain/model/Config/Configuration';

const configuration: Configuration = window.LOG.config;

export const ConfigurationRepository: Configurations = {
  retrieve(): Configuration {
    return configuration;
  },
};
