import {PageEventView} from '@domain/model/Event/domain/model/UiEvents';
import {
  AppEvent,
  ExperimentEvent,
  MyLookieroActionEvent,
  NavigationEvent,
  PromoCodeEvent,
  UserEvent,
} from '@domain/model/Event';
import {LOOK_IN_EVENT} from '@lookiero/look-in';
import {PayEvent} from '@domain/model/Event/domain/model/PayEvent';

const VIEW_TO_SECTION: Record<PageEventView, string> = Object.freeze({
  [PageEventView.LOGIN]: 'login',
  [PageEventView.ACCOUNT]: 'account_menu',
  [PageEventView.CHECKOUT_PREVIEW]: 'myLK_checkoutPreview',
  [PageEventView.SUBSCRIPTION]: 'myLK_Order',
  [PageEventView.FIRST_SUBSCRIPTION]: 'myLK_1stOrder',
  [PageEventView.FIRST_SCHEDULE]: 'myLK_1stCalendarOrder',
  [PageEventView.SCHEDULE]: 'myLK_CalendarOrder',
  [PageEventView.SHIP_AND_PAY]: 'myLK_shipAndPay',
  [PageEventView.REGISTER]: 'signUp',
  [PageEventView.FIRST_SHIP_AND_PAY]: 'myLK_1stShipAndPay',
  [PageEventView.REFERRAL]: 'referral',
  [PageEventView.LANDING]: 'referral_landing',
  [PageEventView.FIRST_SHIP]: 'myLK_1stShip',
  [PageEventView.SHIP]: 'myLK_Ship',
  [PageEventView.SURVEY]: 'surveyHowDidYouMeetUs',

  [PageEventView.FIRST_PAY]: 'myLK_1stPay',
  [PageEventView.PAY]: 'myLK_Pay',
  [PageEventView.PLACED_ORDER]: 'myLK_OrderPlaced',
  [PageEventView.TRACKING]: 'myLK_OrderPlacedPS',
  [PageEventView.OPEN_MENU]: 'BurgerMenu',
  [PageEventView.PROFILE_MENU]: 'ProfileMenu',
  // LOOK_IN
  [PageEventView.LOOK_IN_BASKET]: 'shop-basket',
  [PageEventView.LOOK_IN_CHECKOUT]: 'shop-checkout',
  [PageEventView.LOOK_IN_CHECKOUT_SUCCESS]: 'shop-thankYouPage',
  [PageEventView.LOOK_IN_PRODUCT_DETAIL]: 'shop-garmentDetail',
  [PageEventView.LOOK_IN_HOME]: 'shop-home',
  [PageEventView.LOOK_IN_WISHLIST]: 'shop-wishlist',
  [PageEventView.LOOK_IN_CATEGORIES]: 'shop-categories',
  [PageEventView.LOOK_IN_SALES]: 'shop-sales',
  [PageEventView.LOOK_IN_TRENDING_FOR_YOU]: 'shop-trending_for_you',
  // END LOOK_IN

  // QUIZ
  [PageEventView.LEISURE]: 'leisure',
  [PageEventView.WORK]: 'work',
  [PageEventView.FIT]: 'fit',
  [PageEventView.HIGHLIGHT]: 'highlight',
  [PageEventView.BODY_SHAPE]: 'bodyShape',
  [PageEventView.EYES]: 'eyes',
  [PageEventView.HAIR]: 'hair',
  [PageEventView.SIZES]: 'sizes',
  [PageEventView.MEASUREMENTS]: 'measurements',
  [PageEventView.FOCUS]: 'focus',
  [PageEventView.ADVENTUROUS]: 'adventurous',
  [PageEventView.STYLES]: 'styles',
  [PageEventView.PRICES]: 'prices',
  [PageEventView.AVOID]: 'avoid',
  [PageEventView.FOOTWEAR]: 'footwear',
  [PageEventView.YOU]: 'you',
  [PageEventView.PHOTOS]: 'photos',
  [PageEventView.SOCIAL]: 'social',
  // END QUIZ

  [PageEventView.NOT_FOUND]: '404',
  [PageEventView.LOOK_AND_LIKE]: 'lookAndLike',
  [PageEventView.INSPIRATION]: 'inspiration',
  [PageEventView.INBOX]: 'inbox',
});

const TRANSACTIONAL_ACTIONS = [
  UserEvent.ORDER_CANCELLED,
  UserEvent.ORDER_PLACED,
  UserEvent.ORDER_SKIPPED,
  UserEvent.SUBSCRIPTION_CANCELLED,
  UserEvent.SUBSCRIPTION_PAUSED,
];
const TRANSACTIONAL_ACTION_TO_NAME: Record<string, string> = {
  [UserEvent.ORDER_CANCELLED]: 'orderCancelled',
  [UserEvent.ORDER_PLACED]: 'orderPlaced',
  [UserEvent.ORDER_SKIPPED]: 'orderSkipped',
  [UserEvent.SUBSCRIPTION_CANCELLED]: 'cancelSubscription',
  [UserEvent.SUBSCRIPTION_PAUSED]: 'pauseSubscription',
};

const SESSION_ACTIONS = [UserEvent.LOGIN, UserEvent.LOGOUT, UserEvent.REGISTER] as const;
const SESSION_ACTION_TO_NAME: Record<string, string> = {
  [UserEvent.LOGIN]: 'signIn',
  [UserEvent.REGISTER]: 'signUp',
  [UserEvent.LOGOUT]: 'signOut',
};

const NAVIGATION_ACTIONS = [
  NavigationEvent.QUIZ_STARTED,
  NavigationEvent.QUIZ_FINISHED,
  NavigationEvent.QUIZ_STEP_SUBMIT,
  NavigationEvent.ONBOARDING_STARTED,
  NavigationEvent.ONBOARDING_FINISHED,
  NavigationEvent.RANGE_PRICES,
  NavigationEvent.IS_MOTHER,
  NavigationEvent.REFERRAL_CODE_SHARE,
  NavigationEvent.DOWNLOAD_APP_BUTTONS,
  NavigationEvent.VIEW_POST_ORDER_MODAL,
  NavigationEvent.CLOSE_POST_ORDER_MODAL,
  NavigationEvent.MANAGE_PLAN,
];
const NAVIGATION_ACTION_TO_NAME: Record<string, string> = {
  [NavigationEvent.QUIZ_STARTED]: 'quizStarted',
  [NavigationEvent.QUIZ_FINISHED]: 'quizFinished',
  [NavigationEvent.QUIZ_STEP_SUBMIT]: 'quiz',
  [NavigationEvent.ONBOARDING_STARTED]: 'onBoardingStarted',
  [NavigationEvent.ONBOARDING_FINISHED]: 'onBoardingFinished',
  [NavigationEvent.RANGE_PRICES]: 'rangePrices',
  [NavigationEvent.IS_MOTHER]: 'isMother',
  [NavigationEvent.REFERRAL_CODE_SHARE]: 'referralcodeshare',
  [NavigationEvent.DOWNLOAD_APP_BUTTONS]: 'downloadAppButton',
  [NavigationEvent.VIEW_POST_ORDER_MODAL]: 'myLKPostOrderModalView',
  [NavigationEvent.CLOSE_POST_ORDER_MODAL]: 'myLKPostOrderModalClose',
  [NavigationEvent.MANAGE_PLAN]: 'managePlan',
};

const MY_LOOKIERO_EVENTS: MyLookieroActionEvent[] = [
  MyLookieroActionEvent.OPEN_CHECKOUT,
  MyLookieroActionEvent.PREVIEW_CLICK,
  MyLookieroActionEvent.STEPPER_CLICK,
  MyLookieroActionEvent.TRACKING_CLICK,
  MyLookieroActionEvent.SEND_COMMENT,
];

const HOME_VIEWS = [PageEventView.SUBSCRIPTION, PageEventView.FIRST_SUBSCRIPTION];

const EXPERIMENT_ACTIONS = [ExperimentEvent.EXPERIMENT_APPLIED];

const LOOK_IN_ACTIONS = [
  LOOK_IN_EVENT.BACK_TO_TOP,
  LOOK_IN_EVENT.BASKET_ITEM_ADDED,
  LOOK_IN_EVENT.BASKET_ITEM_REMOVED,
  LOOK_IN_EVENT.BASKET_DISPLAYED,
  LOOK_IN_EVENT.CHECKOUT_SOLD_OUT,
  LOOK_IN_EVENT.CHECKOUT_STARTED,
  LOOK_IN_EVENT.CHECKOUT_SUCCESS,
  LOOK_IN_EVENT.ITEM_SELECTED,
  LOOK_IN_EVENT.ITEM_DETAIL_DISPLAYED,
  LOOK_IN_EVENT.ITEM_DETAIL_SOLD_OUT,
  LOOK_IN_EVENT.PAYMENT,
  LOOK_IN_EVENT.VIEW_ITEM_LIST,
  LOOK_IN_EVENT.COMPLETE_YOUR_LOOK_SELECTED,
  LOOK_IN_EVENT.CATEGORIES_SELECTED,
  LOOK_IN_EVENT.CATEGORY_TAB_SELECTED,
  LOOK_IN_EVENT.WISHLIST_ITEM_ADDED,
  LOOK_IN_EVENT.WISHLIST_ITEM_REMOVED,
  LOOK_IN_EVENT.SELECT_ANCHOR_GARMENT,
  LOOK_IN_EVENT.SHOP_BUTTON_CLICKED,
  LOOK_IN_EVENT.EMPTY_STATE,
  LOOK_IN_EVENT.VIEW_WISHLIST,
  LOOK_IN_EVENT.SHOW_NOTIFY_STOCK_BUTTON,
  LOOK_IN_EVENT.NOTIFY_STOCK_BUTTON_CLICKED,
  LOOK_IN_EVENT.CLOSE_STOCK_OUT_MODAL,
  LOOK_IN_EVENT.REMOVE_AND_PAY_STOCK_OUT_MODAL,
  LOOK_IN_EVENT.SHOW_STOCK_OUT_MODAL,
  LOOK_IN_EVENT.LOOK_AND_LIKE_BANNER_CLICKED,
  LOOK_IN_EVENT.SHOP_EDIT_SHIPPING_INFO,
  LOOK_IN_EVENT.PROMOCODE_INVALID,
  LOOK_IN_EVENT.PROMOCODE_VALID,
  LOOK_IN_EVENT.AFTER_CHECKOUT_ACCESS_GRANTED,
  LOOK_IN_EVENT.REDIRECT_TO_CATEGORIES_FROM_CYL,
  LOOK_IN_EVENT.ANCHOR_ITEM_LIST,
];

const APP_ACTIONS = [AppEvent.ACCEPT_UPDATE_VERSION, AppEvent.SHOW_UPDATE_VERSION, AppEvent.SKIP_UPDATE_VERSION];

const PROMO_CODE_ACTIONS = [PromoCodeEvent.PROMO_CODE_APPLIED];

const PAY_ACTIONS = [PayEvent.PAY_PAGE_CTA_PRESSED];

export {
  APP_ACTIONS,
  LOOK_IN_ACTIONS,
  EXPERIMENT_ACTIONS,
  HOME_VIEWS,
  NAVIGATION_ACTION_TO_NAME,
  NAVIGATION_ACTIONS,
  SESSION_ACTION_TO_NAME,
  SESSION_ACTIONS,
  TRANSACTIONAL_ACTIONS,
  TRANSACTIONAL_ACTION_TO_NAME,
  VIEW_TO_SECTION,
  PROMO_CODE_ACTIONS,
  MY_LOOKIERO_EVENTS,
  PAY_ACTIONS,
};
