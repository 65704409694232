import {useEffect, useState} from 'react';
import {LocationApplication} from '@application/Site';
import {useUpdateLocale} from '../../i18n/MessagesProvider';

export const useLocale = () => {
  const [locale, setLocale] = useState({});
  const {updateLocale} = useUpdateLocale();

  useEffect(() => {
    LocationApplication.getLocale().then(setLocale);
  }, [updateLocale]);

  return locale;
};
