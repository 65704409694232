import {FC, lazy} from 'react';

const toDefault =
  (key: string) =>
  ({[key]: component}: Record<string, FC>) => ({default: component});

const Account = lazy(() => import('../../ui/views/Account').then(toDefault('Account')));
const Login = lazy(() => import('../../ui/views/Login').then(toDefault('Login')));
const MyLookiero = lazy(() => import('../../ui/views/MyLookiero').then(toDefault('MyLookiero')));
const ResetPassword = lazy(() => import('../../ui/views/ResetPassword').then(toDefault('ResetPassword')));
const ReferralsSummary = lazy(() =>
  import('../../ui/views/Referrals/ReferralsSummary').then(toDefault('ReferralsSummary')),
);
const Referrals = lazy(() => import('../../ui/views/Referrals/Referrals').then(toDefault('Referrals')));
const LookIn = lazy(() => import('../look-in/ui/views/LookIn').then(toDefault('LookIn')));
const PublicReturnOrder = lazy(() => import('../../ui/views/PublicReturnOrder').then(toDefault('PublicReturnOrder')));
const ProfileMenu = lazy(() => import('../../ui/views/ProfileMenu/ProfileMenu').then(toDefault('ProfileMenu')));
const Checkout = lazy(() => import('../checkout/ui/views/checkout/Checkout').then(toDefault('Checkout')));
const Quiz = lazy(() => import('../quiz/ui/views/quiz/Quiz').then(toDefault('Quiz')));
const QuizRegister = lazy(() =>
  import('../../ui/views/Style/QuizRegister/QuizRegister.view').then(toDefault('QuizRegisterView')),
);
const StyleProfile = lazy(() =>
  import('../styleProfile/ui/views/styleProfile/StyleProfile').then(toDefault('StyleProfile')),
);
const MyPlan = lazy(() => import('../../ui/views/Account/Plan/MyPlan').then(toDefault('MyPlan')));

export {
  Account,
  Login,
  MyLookiero,
  ResetPassword,
  ReferralsSummary,
  Referrals,
  LookIn,
  PublicReturnOrder,
  ProfileMenu,
  Checkout,
  Quiz,
  QuizRegister,
  StyleProfile,
  MyPlan,
};
