import {useMemo} from 'react';
import {useSettings} from '@components/_context';
import {MenuEntry, Link, LINKS} from './Links';
import {useApp} from '@app';
import {SITE} from '@domain/model/Site/Site';
import {useLookIn} from '@modules/look-in/ui/hooks/useLookIn';

const SITES_WITHOUT_BLOG: string[] = [SITE.SE, SITE.CH];

export const useMenuLinks = (): {isLoading: boolean; links: Link[]} => {
  const {authorization} = useApp();
  const {site = '', loading: settingsLoading} = useSettings();
  const {canAccess: shopAccess, loading} = useLookIn();

  const isLoggedIn = Boolean(authorization);
  const isLoading = !site || settingsLoading || (isLoggedIn && loading);
  const isBlogAvailable = site && !SITES_WITHOUT_BLOG.includes(site);
  const isShopAvailable = isLoggedIn && shopAccess;

  return useMemo(() => {
    if (isLoading) {
      return {
        isLoading: true,
        links: [],
      };
    }

    const links = [
      ...(isShopAvailable ? [LINKS[MenuEntry.LOOK_IN]] : []),
      ...(isLoggedIn ? [LINKS[MenuEntry.STYLE], LINKS[MenuEntry.ACCOUNT]] : []),
      ...(isBlogAvailable ? [LINKS[MenuEntry.BLOG]] : []),
      LINKS[MenuEntry.GIFTCARD],
      LINKS[MenuEntry.HELP],
      LINKS[MenuEntry.TERMS],
    ];

    return {
      isLoading: false,
      links: links,
    };
  }, [isLoading, isShopAvailable, isLoggedIn, isBlogAvailable, site]);
};
