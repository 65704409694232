import {setPromocodesBridge} from '@lookiero/promocodes-front';
import {getAuth} from '@infra/api/authManager/authManager';
import {useAvoidKeyboard} from '../../../ui/components/layouts/keyboardAvoidingView/useAvoidKeyboard';
import {emitUserEvent} from '@lookiero/user-tracking-front';
import {useFeatureFlags} from '@components/_context';
import {proxy} from '@infra/api/fetch/proxy';
import packageJson from '../../../../package.json';

export const configurePromoCodes = (): void => {
  setPromocodesBridge({
    getToken: getAuth,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useAvoidKeyboard,
    emitTrackingEvent: emitUserEvent,
    appVersion: packageJson.version,
    useFeatureFlags,
    proxy: proxy,
  });
};
