import StyleSheet from 'react-native-extended-stylesheet';

const style = StyleSheet.create({
  optionGap: {
    marginBottom: '$space2',
  },
  option: {
    flexDirection: 'row',
    width: '100%',
    paddingVertical: '$space4',
    paddingHorizontal: '$space3',
    borderWidth: '$borderWidth1',
    borderStyle: '$borderStyle',
    alignItems: 'center',
    borderRadius: '$borderRadius3',
  },
  unselectedOption: {
    borderColor: '$colorBorderInteractive',
  },
  selectedOption: {
    borderWidth: '$borderWidth2',
  },
  unselectedText: {
    color: '$colorTextMedium',
  },
  check: {
    alignSelf: 'flex-start',
    marginRight: '$space4',
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    color: '$colorText',
  },
  labelContainer: {
    marginLeft: '$space2',
    paddingVertical: '$space05',
    paddingHorizontal: '$space1',
    borderRadius: '$borderRadius2',
  },
  description: {
    marginTop: '$space1',
  },
});

export {style};
