import {Platform} from 'react-native';
import React, {FC, ReactNode} from 'react';
import {Aurora as AuroraWrapper, DISPLAY, FLEX_DIRECTION, DefaultTheme, ThemeOutfittery} from '@lookiero/aurora';
import {ConfigurationApplication} from '@application/Config';
import {TradeName} from '@domain/model/Config/Configuration';

const isNative = Platform.OS === 'ios' || Platform.OS === 'android';

interface AuroraProps {
  readonly children: ReactNode;
}

const auroraProps = isNative ? {display: DISPLAY.FLEX, flexDirection: FLEX_DIRECTION.COLUMN, wide: true} : {};

const Aurora: FC<AuroraProps> = ({children}) => {
  const {tradename} = ConfigurationApplication.retrieve();

  return (
    <AuroraWrapper {...auroraProps} theme={tradename === TradeName.LOOKIERO ? DefaultTheme : ThemeOutfittery}>
      {children}
    </AuroraWrapper>
  );
};

export {Aurora};
