import React, {FC, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {ALIGN, Button, Link, SPACE, useDevice} from '@lookiero/aurora';

import {AppApplication} from '@application/App';
import {MountableModal} from '@components/molecules';
import {useModal, MODAL_STATUS} from '@components/_context/ModalManager';
import {useStore} from '@components/hooks';
import {AppEvent} from '@domain/model/Event';
import {VersionStatus} from '@domain/model/App/VersionStatus';
import {Platform} from '@domain/model/App/Platform';

import {openExternalLink} from '@router/helpers';
import {eventManager} from '@application/Event';

import {TEXT} from './ModalUpdateVersion.definition';
import {getVersion} from 'react-native-device-info';

type NativePlatform = 'android' | 'ios';

const getPlatform = (platform: Platform): NativePlatform => (platform.ios ? 'ios' : 'android');

const ModalUpdateVersion: FC = () => {
  const {platform} = useDevice();
  const {formatMessage} = useIntl();
  const {nativeStore} = useStore();
  const appVersion = getVersion();

  const [versionStatus, setVersionStatus] = useState<VersionStatus>();

  const {isOpen, closeModal, openModal} = useModal('update-version', MODAL_STATUS.LOADING);

  useEffect(() => {
    const getVersionStatus = async (): Promise<void> => {
      try {
        const versionStatus = await AppApplication.getVersionStatus({
          platform: getPlatform(platform),
          version: appVersion,
        });
        setVersionStatus(versionStatus);
        openModal();
      } catch (error) {
        closeModal();
      }
    };

    if (appVersion) getVersionStatus();
  }, [openModal, closeModal, platform, appVersion]);

  useEffect(() => {
    if (isOpen) {
      eventManager.emit(AppEvent.SHOW_UPDATE_VERSION);
    }
  }, [isOpen]);

  const openStore = (): void => {
    eventManager.emit(AppEvent.ACCEPT_UPDATE_VERSION);
    if (nativeStore) void openExternalLink({url: nativeStore});
  };

  const onClose = (): void => {
    eventManager.emit(AppEvent.SKIP_UPDATE_VERSION);
    closeModal();
  };

  return (
    <MountableModal
      description={formatMessage(TEXT.DESCRIPTION)}
      isVisible={isOpen}
      onClose={versionStatus === VersionStatus.RECOMMEND ? onClose : undefined}
      portalID="update-version"
      title={formatMessage(TEXT.TITLE)}
    >
      <Button onPress={openStore}>{formatMessage(TEXT.CTA_YES)}</Button>
      {versionStatus === VersionStatus.RECOMMEND && (
        <Link action align={ALIGN.CENTER} level={3} marginTop={SPACE.SPACE_4} onPress={onClose} underlined>
          {formatMessage(TEXT.CTA_NO)}
        </Link>
      )}
    </MountableModal>
  );
};

export {ModalUpdateVersion};
