import React, {ReactNode, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo} from 'react';
import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import {InAppBrowserBanner} from '@components/atoms';
import {IOS_IN_APP_BROWSER_VIDEO_AD_PADDING_BOTTOM, iOSInAppBrowserVideoAd} from '@helpers';

interface IOSInAppBrowserVideoAdScrollViewProps {
  readonly children: ReactNode;
  readonly scrollEnabled?: boolean;
  readonly style?: StyleProp<ViewStyle>;
  readonly onScroll?: ((event: NativeSyntheticEvent<NativeScrollEvent>) => void) | undefined;
}

const IOSInAppBrowserVideoAdScrollView = forwardRef<ScrollView, IOSInAppBrowserVideoAdScrollViewProps>(
  ({children, scrollEnabled, style: customStyle, onScroll}, ref) => {
    const handleOnScroll = useCallback(
      () =>
        onScroll &&
        onScroll({
          nativeEvent: {
            contentOffset: {
              x: window.scrollX,
              y: window.scrollY,
            },
            contentSize: {
              height: window.document.body.scrollHeight,
              width: window.document.body.scrollWidth,
            },
            layoutMeasurement: {
              height: window.innerHeight,
              width: window.innerWidth,
            },
          },
        } as NativeSyntheticEvent<NativeScrollEvent>),
      [onScroll],
    );

    useEffect(() => {
      window.addEventListener('scroll', handleOnScroll, {passive: true});

      return () => {
        window.removeEventListener('scroll', handleOnScroll);
      };
    }, [handleOnScroll]);

    useImperativeHandle(
      ref,
      () =>
        ({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          scrollTo({y, x, animated}) {
            window.scrollTo({
              left: x,
              top: y,
              behavior: animated ? 'smooth' : 'auto',
            });
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          scrollToEnd({animated}) {
            window.scrollTo({
              left: 0,
              top: document.body.scrollHeight,
              behavior: animated ? 'smooth' : 'auto',
            });
          },
        }) as ScrollView,
    );

    const style: StyleProp<ViewStyle> = useMemo(() => StyleSheet.flatten(customStyle), [customStyle]);

    const paddingBottom = style?.paddingBottom ? Number(style.paddingBottom) : 0;

    return (
      <View
        testID="ios-in-app-browser-video-ad-scroll-view"
        style={[
          !scrollEnabled && {overflow: 'hidden', height: '100%'},
          style,
          {
            paddingBottom: paddingBottom + IOS_IN_APP_BROWSER_VIDEO_AD_PADDING_BOTTOM,
          },
        ]}
      >
        {iOSInAppBrowserVideoAd && <InAppBrowserBanner />}
        {children}
      </View>
    );
  },
);

IOSInAppBrowserVideoAdScrollView.displayName = 'IOSInAppBrowserVideoAdScrollView';

export {IOSInAppBrowserVideoAdScrollView};
