import {Path} from '@modules/routing/routes.definition';
import {Platform} from 'react-native';

export enum MenuEntry {
  STYLE = 'STYLE',
  ACCOUNT = 'ACCOUNT',
  LOOK_IN = 'LOOK_IN',
  BLOG = 'BLOG',
  GIFTCARD = 'GIFTCARD',
  HELP = 'HELP',
  TERMS = 'TERMS_AND_CONDS',
}

export interface Link {
  id: string;
  path: Path | string;
  translationKey: string;
  testID: string;
  external: boolean;
}

const HEADLESS_PARAM = Platform.OS === 'web' ? '' : '?headless=true';

export const LINKS: Record<MenuEntry, Link> = {
  [MenuEntry.LOOK_IN]: {
    id: MenuEntry.LOOK_IN,
    path: Path.LOOK_IN_ORDERS,
    translationKey: 'shop.menu.item.my_orders',
    testID: 'link-look-in-orders',
    external: false,
  },
  [MenuEntry.STYLE]: {
    id: MenuEntry.STYLE,
    path: Path.STYLE_PROFILE_HOME,
    translationKey: 'navigation.menu.style',
    testID: 'link-style',
    external: false,
  },
  [MenuEntry.ACCOUNT]: {
    id: MenuEntry.ACCOUNT,
    path: Path.ACCOUNT,
    translationKey: 'navigation.menu.account',
    testID: 'link-account',
    external: false,
  },
  [MenuEntry.BLOG]: {
    id: MenuEntry.BLOG,
    path: '/blog',
    translationKey: 'navigation.menu.blog',
    testID: 'link-blog',
    external: true,
  },
  [MenuEntry.GIFTCARD]: {
    id: MenuEntry.GIFTCARD,
    path: `/giftcard/${HEADLESS_PARAM}`,
    translationKey: 'navigation.menu.giftCard',
    testID: 'link-giftcard',
    external: true,
  },
  [MenuEntry.HELP]: {
    id: MenuEntry.HELP,
    path: `/help-and-contact${HEADLESS_PARAM}`,
    translationKey: 'navigation.menu.help',
    testID: 'link-help',
    external: true,
  },
  [MenuEntry.TERMS]: {
    id: MenuEntry.TERMS,
    path: `/terms-and-conds/${HEADLESS_PARAM}`,
    translationKey: 'navigation.menu.tc',
    testID: 'link-terms',
    external: true,
  },
};
