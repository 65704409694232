export enum UiEvent {
  PAGE_LOAD = 'ui.pageload',
}

export enum PageEventView {
  LOGIN,
  REGISTER,
  SUBSCRIPTION,
  FIRST_SUBSCRIPTION,
  FIRST_SCHEDULE,
  SCHEDULE,
  CHECKOUT_PREVIEW,
  ACCOUNT,
  FIRST_SHIP_AND_PAY,
  SHIP_AND_PAY,
  SURVEY,
  REFERRAL,
  LANDING,
  NOT_FOUND,
  FIRST_SHIP,
  SHIP,
  FIRST_PAY,
  PAY,
  TRACKING,
  PLACED_ORDER,
  OPEN_MENU,
  PROFILE_MENU,
  LOOK_IN_BASKET,
  LOOK_IN_CHECKOUT,
  LOOK_IN_CHECKOUT_SUCCESS,
  LOOK_IN_HOME,
  LOOK_IN_PRODUCT_DETAIL,
  LOOK_IN_WISHLIST,
  LOOK_IN_CATEGORIES,
  LOOK_IN_SALES,
  LOOK_IN_TRENDING_FOR_YOU,
  // QUIZ
  LEISURE,
  WORK,
  FIT,
  HIGHLIGHT,
  BODY_SHAPE,
  EYES,
  HAIR,
  SIZES,
  MEASUREMENTS,
  FOCUS,
  ADVENTUROUS,
  STYLES,
  PRICES,
  AVOID,
  FOOTWEAR,
  YOU,
  PHOTOS,
  SOCIAL,
  // OTHERS
  LOOK_AND_LIKE,
  INSPIRATION,
  INBOX,
}

export interface PageViewEventPayload {
  view: PageEventView;
  category?: string;
  userEmail?: string;
  userFirstName?: string;
  userLastName?: string;
  userPhone?: string;
  userCity?: string;
  userZip?: string;
  userUuid?: string;
  userId?: string;
  calendarDays?: number;
}
