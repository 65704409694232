export enum Environment {
  DEVELOPMENT = 'DEVELOPMENT',
  PRODUCTION = 'PRODUCTION',
}

export enum TradeName {
  LOOKIERO = 'LOOKIERO',
  OUTFITTERY = 'OUTFITTERY',
}

export interface Configuration {
  assetsPath: string;
  commonAssetsPath: string;
  environment: Environment;
  googleWebId: string;
  kameleoonApiKey: string;
  paymentAssetsPath: string;
  shopAssetsPath: string;
  stripeKey: string;
  stripeUKKey: string;
  tradename: TradeName;
}
