import {LOOK_IN_EVENT} from '@lookiero/look-in';
import React, {FC, createContext, useContext, useEffect, useMemo, useState} from 'react';
import {useEnabledOfferings, EnabledOfferings} from '@lookiero/look-in';
import {useApp} from '@app';
import {getAuth} from '@infra/api/authManager/authManager';
import {eventManager} from '@application/Event';
import {CheckoutEvent} from '@domain/model/Event';
import debounce from 'lodash.debounce';
import {useFeatureFlags} from '../../../../ui/components/_context/FeatureFlagContext';
import {DomainEvent} from '../../../../typings/domainEvent';

interface LookInContext {
  readonly canAccess: boolean;
  readonly enabledOfferings: EnabledOfferings | undefined;
  readonly loading: boolean;
}

interface LookInProviderProps {
  readonly children: React.ReactNode;
}

const LookInContext = createContext<LookInContext>({} as LookInContext);

const LookInProvider: FC<LookInProviderProps> = ({children}) => {
  const {authorization} = useApp();
  const [authToken, setAuthToken] = useState<string>();

  const featureFlags = useFeatureFlags();
  const {enabledOfferings, fetchEnabledOfferings} = useEnabledOfferings({authToken});

  useEffect(() => {
    (async () => {
      const token = await getAuth();
      if (token) {
        setAuthToken(`Bearer ${token}`);
      }
    })();
  }, [authorization]);

  const debouncedRefetchAccesesAfterCheckout = useMemo(
    () =>
      debounce(async () => {
        await fetchEnabledOfferings();

        eventManager.emit(LOOK_IN_EVENT.AFTER_CHECKOUT_ACCESS_GRANTED as DomainEvent, {
          enabledOfferings,
        });
      }, 100),
    [enabledOfferings, fetchEnabledOfferings],
  );

  useEffect(() => {
    eventManager.subscribe(CheckoutEvent.CHECKOUT_SUBMITTED, debouncedRefetchAccesesAfterCheckout);

    return () => eventManager.unsubscribe(CheckoutEvent.CHECKOUT_SUBMITTED, debouncedRefetchAccesesAfterCheckout);
  });

  const lookInFeatureFlag = featureFlags.DIRECT_BUY || false;

  const canAccess = Boolean(lookInFeatureFlag && enabledOfferings && Object.values(enabledOfferings).some(Boolean));
  const loading = !authToken || featureFlags.DIRECT_BUY === undefined || enabledOfferings === undefined;

  const value = useMemo(
    () => ({
      canAccess,
      enabledOfferings,
      loading,
    }),
    [canAccess, loading, enabledOfferings],
  );

  return <LookInContext.Provider value={value}>{children}</LookInContext.Provider>;
};

const useLookIn = (): LookInContext => useContext(LookInContext);

export {LookInProvider, useLookIn};
