import {AppleRepository} from '@infra/domain/model/Apple/InvertaseAppleRepository';
import {ApiAuthRepository} from '@infra/domain/model/Auth/ApiAuthRepository';
import {ApiAccountRepository} from '@infra/domain/model/Account/ApiAccountRepository';
import {IsAppleLoginSupported} from '@application/Auth/IsAppleLoginSupported';
import {RefreshSession} from '@application/Auth/RefreshSession';
import {AuthLoginGoogle} from '@domain/model/Auth/AuthLoginGoogle';

import {GetLoginInfo} from './GetLoginInfo';
import {HandleAppleAuthentication} from './HandleAppleAuthentication';
import {HandleGoogleLogin} from './HandleGoogleLogin';
import {IsFirstLogin} from './IsFirstLogin';
import {IsLoggedIn} from './IsLoggedIn';
import {Login} from './Login';
import {RecoverPassword} from './RecoverPassword';
import {RegisterUser} from './RegisterUser';
import {SetHasLoggedIn} from './SetHasLoggedIn';
import {ApplyFirstSession, ClearFirstSession, IsFirstSession} from './FirstSession';
import {LoginWithFaceBook} from '@application/Auth/LoginWithFaceBook';
import {RegisterWithFaceBook} from '@application/Auth/RegisterWithFaceBook';

export const AuthApplication = {
  login: Login(ApiAuthRepository),
  isLoggedIn: IsLoggedIn(ApiAuthRepository),
  isFirstLogin: IsFirstLogin(),
  handleAppleAuthentication: HandleAppleAuthentication(AppleRepository, ApiAccountRepository),
  handleGoogleLogin: (loginCommand: AuthLoginGoogle) =>
    HandleGoogleLogin({loginCommand, apiAuthRepository: ApiAuthRepository}),
  recoverPassword: RecoverPassword(ApiAuthRepository),
  registerUser: RegisterUser.bind(null, ApiAuthRepository),
  isAppleLoginSupported: IsAppleLoginSupported(AppleRepository),
  getLoginInfo: GetLoginInfo.bind(null, ApiAuthRepository),
  setHasLoggedIn: SetHasLoggedIn(),
  refreshSession: RefreshSession(),
  applyFirstSession: ApplyFirstSession(),
  clearFirstSession: ClearFirstSession(),
  isFirstSession: IsFirstSession(),
  loginWithFaceBook: LoginWithFaceBook(ApiAuthRepository),
  registerWithFaceBook: RegisterWithFaceBook(ApiAuthRepository),
};
