import 'react-native-get-random-values';
import 'react-native-gesture-handler';
import {LogBox, Platform} from 'react-native';
import {registerRootComponent} from 'expo';
import {activateKeepAwake} from 'expo-keep-awake';
import {enableLegacyWebImplementation} from 'react-native-gesture-handler';
import {setProxy} from '@lookiero/data-sources';
import {configurePayments} from './src/ui/app/App';
import {configurePromoCodes} from '@modules/promoCodes/config';
import {getProxy} from './config/proxy';
import {replaceConfigUrlsWithTopLevelDomain} from './src/ui/helpers/replaceConfigUrlsWithTopLevelDomain';
import {App} from './src/ui/app/App';

/**
 * This is required for the Slider (checkout's new implementation) to properly work within a ScrollView.
 */
enableLegacyWebImplementation(true);

// eslint-disable-next-line no-undef
if (__DEV__ && Platform.OS === 'web') {
  activateKeepAwake();
}

if (Platform.OS !== 'web') {
  LogBox.ignoreAllLogs(true);
  setProxy(getProxy());
  replaceConfigUrlsWithTopLevelDomain();
}
configurePayments();
configurePromoCodes();

registerRootComponent(App);
