import ApolloClient, {
  ApolloQueryResult,
  DocumentNode,
  FetchResult,
  InMemoryCache,
  OperationVariables,
} from 'apollo-boost';
import {GraphQLError} from 'graphql';

import {eventManager} from '@application/Event';
import {UserEvent} from '@domain/model/Event';
import {ApiAuthRepository} from '@infra/domain/model/Auth/ApiAuthRepository';
import {getAuthHeader} from '@infra/api/authManager/authManager';
import {fetch} from '@infra/api/fetch';

const GRAPHQL_ERROR_SYNTAX = 'GraphQL error: ';

const cache = new InMemoryCache({addTypename: false});
let GraphqlClientStatic: ApolloClient<InMemoryCache> | undefined;

// We can't create the ApolloClient when the module is loaded because React Native changes the fetch function asynchronously
// and Apollo uses fetch inside the Client creation.
const GraphqlClient = (): ApolloClient<InMemoryCache> => {
  if (GraphqlClientStatic !== undefined) {
    return GraphqlClientStatic;
  }

  GraphqlClientStatic = new ApolloClient({
    cache: cache,
    version: '0.0.1',
    uri: '/graphql',
    fetch,
    request: async operation => {
      operation.setContext({
        headers: {...(await getAuthHeader())},
      });
    },
    onError: ({graphQLErrors = []}) => {
      const {message: errorCode} = graphQLErrors[0] || {};
      if (errorCode === '401 Unauthorized') {
        ApiAuthRepository.logOut();
        eventManager.emit(UserEvent.UNAUTHORIZED);
      }
    },
  });
  return GraphqlClientStatic;
};

type ResponseData<T> = T & {errors?: ReadonlyArray<GraphQLError>};
const getPromiseData = <T>(data: ResponseData<T>): Promise<T> => {
  const {errors} = data;
  if (!errors || !errors.length) {
    return Promise.resolve(data);
  }
  const [, errorCode] = errors[0].message.split(GRAPHQL_ERROR_SYNTAX);
  return Promise.reject(errorCode ? new Error(errorCode) : errors[0]);
};

const executeQuery = async <T>(query: DocumentNode, variables?: OperationVariables): Promise<T> => {
  const {data, errors}: ApolloQueryResult<ResponseData<T>> = await GraphqlClient().query({
    query,
    variables,
    fetchPolicy: 'network-only',
  });

  return getPromiseData<ResponseData<T>>({errors, ...data});
};

const getError = (err = ''): string => err.replace(new RegExp(GRAPHQL_ERROR_SYNTAX, 'gi'), '');

const executeMutation = async <T>(mutation: DocumentNode, variables: OperationVariables): Promise<T> => {
  try {
    const {data, errors}: FetchResult<ResponseData<T>> = await GraphqlClient().mutate({
      mutation,
      variables,
    });
    if (!data) {
      return Promise.reject(new Error());
    }
    return getPromiseData<ResponseData<T>>({errors, ...data});
  } catch (err) {
    return Promise.reject(new Error(getError(err.message)));
  }
};

export {executeQuery, executeMutation};
